import cn from 'classnames';
import { FC, useState } from 'react';

import { Icon } from '@/assets/icon';
import { useCurrentStore } from '@/shared/lib/hooks';
import { ButtonWrapper, PageUI } from '@/shared/ui';
import { ChildrenProps } from '@/types';

import { BaseGeneralPart } from '../types';
import { Category } from './parts';
import S from './styles.module.scss';

export const Options: FC<Partial<ChildrenProps> & Omit<BaseGeneralPart, 'title'>> = ({ children, tab }) => {
    const [isOpen, setOpen] = useState(false);
    const { options } = useCurrentStore();

    const toggleOpen = () => setOpen((prev) => !prev);

    return (
        <PageUI.BlockItem
            className={cn(S.block, { [S.block__collapsed]: !isOpen })}
            title={
                <ButtonWrapper className={cn(S.heading, { [S.heading__open]: isOpen })} onClick={toggleOpen}>
                    <span>Дополнительная информация о грузе</span>
                    <Icon.ChevronDown className={S.arrow} />
                </ButtonWrapper>
            }
        >
            <div className={cn(S.options, { [S['options--open']]: isOpen })}>
                <div className={cn(S.options__inner)}>
                    {children}
                    {options.map((item) => (
                        <Category {...item} key={`category-${item.id}`} tab={tab} />
                    ))}
                </div>
            </div>
        </PageUI.BlockItem>
    );
};
