import { ValidateOptions } from '@/shared/modules/form';
import { CalculateForm } from '@/types';

import { boolean, custom, number, string, validateOptionsRegistration } from '../../general';

export const validateOptions: ValidateOptions<Partial<CalculateForm>> = {
    ftlfromAddress: string,
    ftltoAddress: string,
    ftlOptions: custom,
    vehicleId: number,
    ftlPaymentMethod: number,
    ftlPaymentTin: string,
    ftlOptionDescription: {
        type: 'string',
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    ftlOptionInsurance: number,
    ftlOptionIsInsured: boolean,
    ltlOptionDescription: {
        type: 'string',
        custom: (value) => {
            if (!value) return false;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    ...validateOptionsRegistration,
};
