export const scrollToFormError = (inputNames: string[], rootElement: HTMLElement) => {
    const inputElements = (
        inputNames
            .map((item) => {
                const element = rootElement.querySelector(`[data-input-name="${item}"]`);

                if (!element) {
                    return null;
                }

                return {
                    element,
                    top: element.clientTop,
                };
            })
            .filter(Boolean) as { element: HTMLElement; top: number }[]
    ).sort((a, b) => a.top - b.top);

    const firstInputElement = inputElements.at(0)?.element;

    firstInputElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
    });
};
