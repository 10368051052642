import cn from 'classnames';
import { FC, useMemo } from 'react';
import React from 'react';

import { useMedia } from '@/shared/lib/hooks';
import { useFormContext } from '@/shared/modules/form/modules';
import { ButtonWrapper, PageUI, Tooltip } from '@/shared/ui';
import { CalculateForm, Cargo as CargoType } from '@/types';

import { Multi, Single } from './parts';
import { Customs } from './parts/custom-options';
import S from './styles.module.scss';

export const Cargo: FC = () => {
    const { field, data } = useFormContext<CalculateForm>();
    const isMobile = useMedia('mobile');

    const tabs = useMemo(
        () =>
            [
                {
                    tab: CargoType.Type.SINGLE,
                    text: ' Общий вес/объем',
                    content: <Single />,
                },
                {
                    tab: CargoType.Type.MULTI,
                    text: isMobile ? 'Отдельные места' : 'Рассчитать с точными габаритами',
                    content: <Multi />,
                },
            ].map((tab) => {
                const isActive = tab.tab === data.cargoType;

                return [
                    <ButtonWrapper
                        className={cn(S.tab, { [S.tab__active]: isActive })}
                        onClick={() => field('cargoType').value(tab.tab)}
                        key={`cargo-tab-${tab.tab}`}
                    >
                        {tab.text}
                    </ButtonWrapper>,
                    isActive && <React.Fragment key={`cargo-tab-${tab.tab}-content`}>{tab.content}</React.Fragment>,
                ];
            }),
        [data.cargoType, isMobile]
    );

    return (
        <PageUI.BlockItem
            title={
                <div className={S.cargo__heading}>
                    <div className={S.cargo__title}>
                        Данные о грузе <Tooltip>Данные о грузе</Tooltip>
                    </div>
                    <div className={S.tabs}>{tabs.map((tab) => tab[0])}</div>
                </div>
            }
        >
            {tabs.map((tab) => tab[1])}
            <Customs />
        </PageUI.BlockItem>
    );
};
